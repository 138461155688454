<template>
  <ConfirmDialog ref="confirmDialog" />
  <TaskPageWrapper task-type="Contract" @formDirtyChange="formDirty = $event" />
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import TaskPageWrapper from "@/components/TaskPages/TaskPageWrapper.vue";

export default {
  name: 'Contracts',
  components: {
    TaskPageWrapper,
    ConfirmDialog,
  },
  inject: ['toast'],
  async beforeRouteLeave() {
    await this.$nextTick(async () => {
      if (this.formDirty) {
        await this.$refs.confirmDialog
          .confirm({
            text:
              'You have unsaved changes.\n' +
              'Are you sure, you want to leave the page?',
            confirmText: 'Leave',
            cancelText: 'Cancel',
            reverse: true,
          })
          .then((response) => {
            if (response) {
              return true
            } else {
              return Promise.reject(false)
            }
          })
      } else {
        return true
      }
    })
  },
  data() {
    return {
      formDirty: false
    }
  }
}
</script>
